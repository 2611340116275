<template>
  <BeforeUnloadBrowserTab :hasUpdates="hasUpdates" />
  <GoodsReceptionHeader
    :editMode="true"
    :hasUpdates="hasUpdates"
    :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
    @on-receive-items="onReceiveItems"
    @saveClicked="onSave"
    @onCancel="onCancel"
    @dialogCancelBtnClicked="stayOnPage"
    @dialogDiscardBtnClicked="routeToPurchaseOrderSearch"
  />
  <div class="c-goods-reception">
    <div class="c-content">
      <div class="c-content-top">
        <div class="c-content-top-left">
          <Card class="c-card-font h-full m-2">
            <template #content>
              <div class="flex flex-wrap mr-4">
                <div class="c-col-full -mt-2 mb-2">
                  <PurchaseOrderInfo
                    :registered="purchaseOrder.registered"
                    :registeredBy="purchaseOrder.registeredByEmployeeName"
                    :purchaseOrderNumber="purchaseOrder.purchaseOrderNumber"
                    :purchaseOrderStatus="purchaseOrder.purchaseOrderStatus"
                  />
                </div>

                <div class="c-col-1 mt-4 pr-4">
                  <div class="mt-3 mb-4 -mx-4">
                    <SupplierInput v-model:supplier="purchaseOrder.supplier.name" />
                  </div>

                  <div class="mb-4 -mx-4">
                    <ContactInput :supplierContact="purchaseOrder.supplier" />
                  </div>

                  <div class="mb-2 -mx-4">
                    <ContactEmailInput v-model:email="purchaseOrder.supplier.contact.email" />
                  </div>
                </div>

                <div class="c-col-2 pl-4">
                  <div class="mt-3">
                    <InformationTabs :supplier="purchaseOrder.supplier" :delivery="purchaseOrder.delivery" />
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>

        <div class="c-content-top-right">
          <Card class="c-card-font h-full m-2">
            <template #content>
              <div class="flex flex-wrap mt-6">
                <div class="c-col-1 pr-4 -mx-3">
                  <div class="mt-6 mb-4">
                    <PurchaseOrderReference v-model:purchaseOrderReference="purchaseOrder.purchaseOrderReference" />
                  </div>

                  <div class="mb-4">
                    <PurchaseWarehouse
                      v-model:warehouseId="purchaseOrder.warehouseId"
                      :purchaseOrderLines="purchaseOrder.purchaseOrderLines"
                    />
                  </div>

                  <div class="flex flex-wrap">
                    <div class="c-col-1 -mr-4">
                      <div class="mb-2 mr-2">
                        <FreightMethod v-model:freightMethod="purchaseOrder.freightMethod.name" />
                      </div>
                    </div>
                    <div class="c-col-2 -mr-4">
                      <div class="mb-2 mr-4">
                        <ShippingPrice class="pl-4" v-model:shippingPrice="purchaseOrder.shippingPrice" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="c-col-2 -mx-3">
                  <div class="mr-1 mb-4">
                    <CommentInput v-model:comment="purchaseOrder.comment" />
                  </div>

                  <div class="mt-3 mb-4 mr-1">
                    <Incoterms v-model:selectedIncoterms="purchaseOrder.supplier.incoterms.description" />
                  </div>

                  <div class="flex flex-wrap">
                    <div class="c-col-1 -mr-4">
                      <div class="mb-2 mr-4">
                        <PaymentTerms v-model:paymentTerm="purchaseOrder.paymentTerm.name" />
                      </div>
                    </div>
                    <div class="c-col-2 mr-1">
                      <div class="mb-2">
                        <PurchaseCurrency v-model:currencyIso="purchaseOrder.currencyIso" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>

      <div class="c-content-bottom mt-8">
        <div class="p-card p-component c-card-font h-full m-2">
          <Suspense>
            <PurchaseOrderLinesToReceive
              ref="purchaseOrderLinesComponentRef"
              v-model:purchaseOrderLinesToReceive="purchaseOrderLinesToReceive"
              :selected-lines="selectedLines"
              :purchaseCurrencyIso="purchaseOrder.currencyIso"
              :clientCurrencyIso="userClient.currencyIso"
              :initialPurchaseOrderLinesToReceive="initialPurchaseOrderLinesToReceive"
              @quantity-changed="nextTick(fetchSummary)"
              @costChangedInPurchaseLine="updateReceivedCostInClientCurrency"
              @selectionUpdated="(lines) => (selectedLines = lines)"
              :allowEdit="allowEditPurchaseOrder"
              :purchaseOrderStatus="editPurchaseOrder.purchaseOrderStatus"
              :goodsReceptions="goodsReceptions"
          /></Suspense>
        </div>
      </div>
      <div class="c-content-bottom mt-4">
        <div class="">
          <Summary :currencyIso="userClient.currencyIso" :summary="summary" @freight-changed="onFreightChanged" />
        </div>
      </div>
    </div>
  </div>

  <PrimeDialog v-model:visible="receiveDialogVisible" :modal="true">
    <template #header>
      <div class="c-under-construction">{{ t("receive-dialog-header") }}</div>
    </template>
    <ReceiveDialog @receiveLines="(reference) => receiveLines(reference)" @cancel="receiveDialogVisible = false" />
  </PrimeDialog>
</template>
<script setup lang="ts">
import GoodsReceptionHeader from "@/components/GoodsReceptionHeader.vue";
import PurchaseOrderLinesToReceive from "@/components/PurchaseOrderLinesToReceive.vue";
import PurchaseOrderInfo from "../components/PurchaseOrderInfo.vue";
import ContactEmailInput from "@/components/ContactEmailInput.vue";
import SupplierInput from "@/components/SupplierInput.vue";
import ContactInput from "@/components/ContactInput.vue";
import InformationTabs from "@/components/info-tabs/InformationTabs.vue";
import PurchaseOrderReference from "@/components/PurchaseOrderReference.vue";
import PurchaseWarehouse from "@/components/PurchaseWarehouse.vue";
import FreightMethod from "@/components/FreightMethod.vue";
import ShippingPrice from "../components/ShippingPrice.vue";
import CommentInput from "../components/comment/CommentInput.vue";
import Incoterms from "../components/Incoterms.vue";
import PaymentTerms from "../components/PaymentTerms.vue";
import PurchaseCurrency from "../components/PurchaseCurrency.vue";
import Summary from "../components/Summary.vue";
import ReceiveDialog from "@/components/ReceiveDialog.vue";

import { onMounted, ref, onBeforeUnmount, computed, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { ShortcutAction, useShortcut } from "@cumulus/shortcut";
import { BeforeUnloadBrowserTab } from "@cumulus/components";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import { useWarehouse } from "@/repository/warehouse/WarehouseService";
import { Warehouse } from "@/repository/warehouse/model/Warehouse";
import { PurchaseOrder } from "@/repository/purchase-order/model/PurchaseOrder";
import { usePurchaseOrder } from "@/api/purchase-order/PurchaseOrderService";
import { GoodsReceptionRequest } from "@/repository/goods-reception/model/GoodsReceptionRequest";
import { GoodsReceptionLine } from "@/repository/goods-reception/model/GoodsReceptionLine";
import { CalculatedGoodsReceptionSummaryResponse } from "@/repository/goods-reception/model/CalculatedGoodsReceptionSummaryResponse";
import { ReceivedQuantity } from "@/repository/goods-reception/model/ReceivedQuantity";
import { useGoodsReception } from "@/api/goods-reception/GoodsReceptionService";
import { PurchaseOrderLine } from "@/repository/purchase-order/model/PurchaseOrderLine";
import { PurchaseOrderLineToReceive } from "@/repository/goods-reception/model/PurchaseOrderLineToReceive";
import { useCurrencyService } from "@/repository/currency/CurrencyService";
import { usePriceFunctions } from "@/utils/priceFunctions";
import { Client } from "@/model/client/Client";
import { useClient } from "@/api/company/ClientService";
import isEqual from "lodash.isequal";
import cloneDeep from "lodash.clonedeep";
import { Currency } from "@/repository/currency/model/Currency";
import { useAuth } from "@cumulus/event-bus";
import { PurchaseOrderStatus } from "@/model/search/purchase-order-search/PurchaseOrderStatus";
import { GoodsReception } from "@/repository/goods-reception/model/GoodsReception";

const { t } = useI18n();
const router = useRouter();
const warehouseService = useWarehouse();
const purchaseOrderService = usePurchaseOrder();
const goodsReceptionService = useGoodsReception();
const { getAllCurrencies } = useCurrencyService();
const { getAuthHeaders } = useAuth();
const { getClient } = useClient();

const { getGoodsReceptionsForPurchaseOrder } = useGoodsReception();
const toast = useCumulusToast(useToast());
const warehouse = ref<Warehouse>(new Warehouse());
const receiveDialogVisible = ref(false);

const goodsReceptions = ref<GoodsReception[]>([]);
const summary = ref<CalculatedGoodsReceptionSummaryResponse>(new CalculatedGoodsReceptionSummaryResponse());
const freight = ref<number>(0);
const selectedLines = ref<PurchaseOrderLineToReceive[]>([]);
const purchaseOrderLinesComponentRef = ref();
const userClient = ref<Client>(new Client());

const purchaseOrderLinesToReceive = ref<PurchaseOrderLineToReceive[]>([]);
const initialPurchaseOrderLinesToReceive = ref<PurchaseOrderLineToReceive[]>([]);
const purchaseOrder = ref<PurchaseOrder>(new PurchaseOrder());
const unsavedChangesDialogVisible = ref(false);
const previouslyFocusedInput = ref<HTMLInputElement | null>(null);
const confirmedDiscard = ref(false);
const currencies = ref<Currency[]>([]);
const purchaseOrderCurrency = ref<Currency>(new Currency());
const route = useRoute();
const { calculatePriceInCurrency, calculateTotalSum } = usePriceFunctions(currencies);

onMounted(async () => {
  document.addEventListener("keydown", handleKeydown);
  const purchaseOrderId = router.currentRoute.value.params.id;

  currencies.value = await getCurrencies();
  userClient.value = await getUserClient();
  purchaseOrder.value = await purchaseOrderService.getPurchaseOrder(purchaseOrderId as string);

  warehouse.value = await warehouseService.getWarehouse(purchaseOrder.value.warehouseId);

  await fetchGoodsReceptionsForPurchaseOrderToReceive();
  purchaseOrderCurrency.value =
    currencies.value.find((x) => x.currencyIso === purchaseOrder.value.currencyIso) ?? ({} as Currency);

  purchaseOrderLinesToReceive.value = purchaseOrder.value.purchaseOrderLines.map((purchaseOrderLine) => {
    const receivedCost = calculatePriceInCurrency(
      purchaseOrderLine.purchasePrice,
      userClient.value.currencyIso,
      purchaseOrder.value.currencyIso
    );
    const sumLine = calculateTotalSum(receivedCost, purchaseOrderLine.quantity);

    return {
      ...purchaseOrderLine,
      quantityToReceive: 0,
      reference: "",
      purchasePrice: purchaseOrderLine.purchasePrice,
      receivedCost,
      sumLine,
    };
  });
  initialPurchaseOrderLinesToReceive.value = cloneDeep(purchaseOrderLinesToReceive.value);
});

const fetchGoodsReceptionsForPurchaseOrderToReceive = async () => {
  const purchaseOrderId = route.params.id as string;
  goodsReceptions.value = await getGoodsReceptionsForPurchaseOrder(purchaseOrderId);
};

const getCurrencies = async () => {
  return await getAllCurrencies();
};

const getUserClient = async () => {
  const authHeaders = await getAuthHeaders();
  const clientId = authHeaders.clientId;
  return await getClient(clientId);
};

const getMaxQuantity = (purchaseOrderLineId: string): number => {
  const line = purchaseOrder.value.purchaseOrderLines.find((x: PurchaseOrderLine) => x.id === purchaseOrderLineId);
  return line != null ? line.quantity - line.receivedQuantity : 0;
};

const receiveLines = (reference: string) => {
  selectedLines.value.length > 0 ? receiveSelectedLines(reference) : receiveAllLines(reference);
  purchaseOrderLinesComponentRef.value.clearSelectedRows();
};

const isModified = (purchaseOrderLinesToReceive: PurchaseOrderLineToReceive) =>
  purchaseOrderLinesToReceive.quantityToReceive !== 0 ||
  purchaseOrderLinesToReceive.purchasePrice !==
    initialPurchaseOrderLinesToReceive.value.find((x) => x.id === purchaseOrderLinesToReceive.id)?.purchasePrice ||
  purchaseOrderLinesToReceive.reference !== "";

const receiveAllLines = (ref: string) => {
  purchaseOrderLinesToReceive.value
    .filter((x) => !isModified(x))
    .forEach((x) => {
      x.reference = ref;
      x.quantityToReceive = getMaxQuantity(x.id);
    });
  receiveDialogVisible.value = false;
  fetchSummary();
};

const editPurchaseOrder = ref<PurchaseOrder>(new PurchaseOrder());
const allowEditPurchaseOrder = computed<boolean>(() => {
  return (
    editPurchaseOrder.value.purchaseOrderStatus !== PurchaseOrderStatus.Received &&
    !editPurchaseOrder.value.inGoodsReception
  );
});

const receiveSelectedLines = (ref: string) => {
  selectedLines.value
    .filter((x) => !isModified(x))
    .forEach((x) => {
      x.reference = ref;
      x.quantityToReceive = getMaxQuantity(x.id);
    });
  receiveDialogVisible.value = false;
  fetchSummary();
};

const onSave = async () => {
  unsavedChangesDialogVisible.value = false;

  try {
    const goodsReceptionLines = purchaseOrderLinesToReceive.value
      .filter((x) => x.quantityToReceive > 0)
      .map((x) => new GoodsReceptionLine().fromPurchaseOrderLineToReceive(x));
    const request = new GoodsReceptionRequest(
      purchaseOrder.value.id,
      freight.value,
      goodsReceptionLines,
      warehouse.value.id,
      userClient.value.currencyIso,
      purchaseOrderCurrency.value?.buyRate ?? 0,
      purchaseOrderCurrency.value?.unit ?? 0
    );
    await goodsReceptionService.addGoodsReception(request);

    toast.add({
      severity: "success",
      summary: t("goods-reception.toast.success.summary"),
      detail: t("goods-reception.toast.success.detail"),
      closable: true,
    });
    routeToPurchaseOrderSearch();
  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: error,
    });
  }
};

const routeToPurchaseOrderSearch = () => {
  confirmedDiscard.value = true;
  if (window.history.state.back === null || window.history.state.back.indexOf("/goods-reception/search") === -1) {
    router.push({ name: "goods-reception-search", query: { search: "" } });
  } else {
    const url = new URL(window.location.origin + window.history.state.back);
    url.searchParams.set("focusResult", purchaseOrder.value.id);
    router.push({ path: window.history.state.back, query: paramsToObject(url.searchParams) });
  }
};

const paramsToObject = (entries: URLSearchParams) => {
  const result = {} as Record<string, string>;
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

useShortcut(ShortcutAction.save, onSave);

const updateReceivedCostInClientCurrency = (purchaseOrderLine: PurchaseOrderLineToReceive) => {
  const calculatedPurchasePrice = calculatePriceInCurrency(
    purchaseOrderLine.purchasePrice,
    userClient.value.currencyIso,
    purchaseOrder.value.currencyIso
  );
  const sumLine = calculateTotalSum(calculatedPurchasePrice, purchaseOrderLine.quantityToReceive);

  purchaseOrderLinesToReceive.value = purchaseOrderLinesToReceive.value.map((line) =>
    line.id === purchaseOrderLine.id
      ? {
          ...line,
          receivedCost: calculatedPurchasePrice,
          sumLine: sumLine,
        }
      : line
  );

  fetchSummary();
};

const fetchSummary = async () => {
  const receivedQuantities = purchaseOrderLinesToReceive.value.map(
    (x) => new ReceivedQuantity(x.quantityToReceive, x.receivedCost)
  );
  summary.value = await goodsReceptionService.calculateGoodsReceptionSummary(receivedQuantities, freight.value);
};

const onFreightChanged = (value: number) => {
  freight.value = value;
  nextTick(fetchSummary);
};

const onReceiveItems = () => {
  receiveDialogVisible.value = true;
};

const hasUpdates = computed(() => {
  return !isEqual(purchaseOrderLinesToReceive.value, initialPurchaseOrderLinesToReceive.value);
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    routeToPurchaseOrderSearch();
  }
};

const stayOnPage = () => {
  unsavedChangesDialogVisible.value = false;
  if (previouslyFocusedInput.value) {
    previouslyFocusedInput.value.focus();
  }
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    event.stopImmediatePropagation();
    if (unsavedChangesDialogVisible.value) {
      stayOnPage();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i" && unsavedChangesDialogVisible.value) {
    routeToPurchaseOrderSearch();
  }
};

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});

window.addEventListener("beforeunload", (e) => {
  if (hasUpdates.value) {
    e.preventDefault();
    e.returnValue = t("common.unsaved-changes-header");
  }
});

onBeforeRouteLeave((_to, _from, next) => {
  if (hasUpdates.value && !confirmedDiscard.value) {
    unsavedChangesDialogVisible.value = true;
    next(false);
  } else {
    next();
  }
});
</script>

<style lang="scss" scoped>
.c-goods-reception {
  margin: var(--default-content-margin);
  margin-bottom: 7rem;
}

.c-content-top {
  display: flex;
  flex-wrap: wrap;
}
.c-content-top-left {
  flex: 50%;
}
.c-content-top-right {
  flex: 50%;
}

.c-col-full {
  flex: 100%;
}

.c-col-1 {
  flex: 50%;
}
.c-col-2 {
  flex: 50%;
}

@media (max-width: 1024px) {
  .c-content-top-left,
  .c-content-top-right {
    flex: 100%;
  }
}

.c-card-font {
  font-size: 12.8px;
}

.c-content {
  position: relative;
  transition: all 0.25s;
}

.c-spinner-container {
  position: relative;
  top: 175px;
}
</style>
